import * as React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Helmut from "../components/helmut"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes

  const [hasMounted, setHasMounted] = React.useState(false);

  React.useEffect( ()=> {
    setHasMounted(true);
  },[]);

  if(!hasMounted)
  {
    return null;
  }

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Helmut title="All posts" />
        <Bio />
        <p>
          No posts found.
        </p>
      </Layout>
    )
  }

  let completepost = posts.map(post => {
    const title = post.frontmatter.title || post.fields.slug

    //const postwithlinebreaks = post.html.replace(new RegExp('\n','g'),'\n<br class="flatbreak">');
    const postwithlinebreaks = post.html;
    return (
      <div key={post.fields.slug}>
            <h2>
              <Link to={post.fields.slug} itemProp="url">
                <span>{title}</span>
              </Link>
            </h2>
            <small>{post.frontmatter.date}</small>
            <div
              className="articleBody"
              dangerouslySetInnerHTML={{
                __html: postwithlinebreaks
              }}
              
            />
          
        
      </div>
    )
  })

  return (
    <Layout location={location} title={siteTitle}>
      <Helmut title="All posts" />
      <div>
        {completepost}
      </div>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark
    (
      sort: { fields: [frontmatter___date], order: DESC },
      filter: {frontmatter: {tags: {in: ["training", "meals"]}}}
    )
    {
      nodes {
        excerpt
        html
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          tags
        }
      }
    }
  }
`
